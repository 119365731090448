import { makeObservable, onBecomeObserved, action, runInAction, computed, comparer, observable } from 'mobx'
import { differenceInDays } from 'date-fns/differenceInDays'
import mapValues from 'lodash/mapValues'
import debounce from 'lodash/debounce'
import makeQuery from 'api/makeQuery'
import GlobalEvents from 'singletons/GlobalEvents'
import Record from 'models/Record'
import increaseDate from 'util/getSnoozeDate'
import {
  getFullAddress,
  getPhone,
  getNotificationStatus,
  getJoinedFields,
  getPreferred,
  getAddressesToSend,
  getPhonesToSend,
  filterNoneFromField,
} from 'util/apiFieldFormatters'
import mergeRanges from 'util/mergeRanges'
import { DAYS_UNTIL_LICENSE_EXPIRE } from 'constants/days'
import PastVisitsCollection from './PastVisitsCollection'
import VisitQueueCollection from './VisitQueueCollection'
import RxChangesCollection from './RxChangesCollection'
import AvailabilityCollection from './AvailabilityCollection'
import BlackoutDatesCollection from './BlackoutDatesCollection'
import Payments from './Payments'
import User from 'singletons/User'

const shouldBeRemovedFromParams = (v) => v === null || v === '' || v === undefined
const formatInitAvailability = (availabilityObj) => {
  const res = {}
  if (availabilityObj) {
    for (const key in availabilityObj) {
      if (key !== 'id') {
        res[key.toUpperCase()] = availabilityObj[key]
      }
    }
  }

  return res
}

const DIRTY_EMPTY_AVAILABILITY = {
  M: [],
  T: [],
  W: [],
  TH: [],
  F: [],
  S: [],
  SU: [],
}

const therapyProviderTypes = ['therapy-all', 'therapy-scheduled', 'therapy-now']

class Provider extends Record {
  static INITIAL_FIELDS = {
    active: false,
    addresses: [],
    bio: '',
    credentials: '',
    dea: '',
    dob: '',
    education: [],
    email: '',
    ethnicity: [],
    externalId: null,
    firstName: '',
    gender: '',
    genderSelfSelect: '',
    imageExt: '',
    imageId: '',
    languages: [],
    lastName: '',
    licenses: [],
    middleInitial: '',
    modalities: [],
    /* sms: true, email: true, snooze: null */
    notifications: {},
    npi: '',
    paymentType: '',
    paymentVisible: false,
    phones: [],
    prefix: '',
    priority: 0,
    profile: {},
    providerId: '',
    publicKey: '',
    /*
      rxvendorid is the external id from dosespot
      will only be for providers who can prescribe medications
      */
    rxVendorId: '',
    resources: null,
    /* {available: true, blackouts: Array(28), id: "604c99e016938d002dee4d4f", availability: {…}} */
    schedule: {},
    specialty: '',
    specialtyFocus: [],
    timezone: '',
    type: '',
    userId: '',
    vendorConfirmed: false,
    policies: [],
    acceptingVisits: false,
  }
  transmissionErrorsList = []
  openedScheduledVisit = {}
  visitQueue = new VisitQueueCollection(this)
  pastVisits = new PastVisitsCollection(this)
  rxChangesList = new RxChangesCollection(this, async () =>
    this.rxVendorId && !User.isHidePrescriptionsFeatureFlagEnabled
      ? await makeQuery('getRxChanges', {
          rxVendorId: this.rxVendorId,
        })
      : [],
  )
  availabilityList = new AvailabilityCollection(this)
  blackoutDatesList = new BlackoutDatesCollection(this)
  payments = new Payments(this)
  constructor(initialInfo) {
    super(initialInfo)
    makeObservable(this, {
      ...mapValues(this.constructor.INITIAL_FIELDS, () => true),
      updateCurrentProvider: action,
      changeSnoozeStatus: action,
      updateProvider: action,
      setOpenedScheduledVisit: action,
      clearOpenedScheduledVisit: action,
      openedScheduledVisit: observable.ref,
      transmissionErrorsList: true,
      isRxChangeAvailable: true,
      phoneToVerify: true,
      welcomeTitle: true,
      isMedical: true,
      policies: observable.shallow,
      expiredLicenses: false, // Think about computed
      address: computed({ equals: comparer.structural }),
      acceptingVisits: true,
      setAcceptingVisits: action,
      initalizeAcceptingVisits: action,
      rxVendorId: observable.ref,
      scheduleVersionConfig: computed,
    })
    this.disposeVisitQueueFetcher = onBecomeObserved(this.visitQueue, 'list', () => {
      if (!this.visitQueue.length) {
        this.refetchCollections()
      }
    })
    this.disposePastVisitsFetcher = onBecomeObserved(this.pastVisits, 'list', () => this.pastVisits.refetch())
    this.disposeRxChangesFetcher = onBecomeObserved(this.rxChangesList, 'list', () => this.rxChangesList.refetch())
    this.disposeTransmissionErrorsFetcher = onBecomeObserved(this, 'transmissionErrorsList', () =>
      this.fetchTransmissionErrors(),
    )
    this.disposeResourcesFetcher = onBecomeObserved(this, 'resources', () => this.updateCurrentProvider())
    this.disposeAvailabilityListFetcher = onBecomeObserved(this.availabilityList, 'days', () =>
      this.availabilityList.recalc(),
    )
    this.disposeBlackoutDatesListFetcher = onBecomeObserved(this.blackoutDatesList, 'dates', () =>
      this.blackoutDatesList.recalc(),
    )
    this.disposePolicyFetcher = onBecomeObserved(this, 'policies', () => {
      if (this.policies.length === 0) {
        this.fetchPolicies()
      }
    })
  }

  async fetchAssignedVisit(pendingVisitId) {
    const visitRecord = await makeQuery('getPendingVisit', {
      providerId: this.providerId,
      visitId: pendingVisitId,
    })
    this.visitQueue.mergeQueue([visitRecord])
  }

  refetchCollections = debounce(async (emitEvent) => {
    const { queued, scheduled } = await makeQuery('getVisits2', {
      providerId: this.providerId,
    })

    console.log('Queued, schedule', queued, scheduled)

    this.visitQueue.mergeQueue([...queued, ...scheduled])

    if (emitEvent) {
      GlobalEvents.emit('refetchCollections')
    }
  }, 500)

  destroy() {
    this.disposeVisitQueueFetcher()
    this.disposePastVisitsFetcher()
    this.disposeRxChangesFetcher()
    this.disposeTransmissionErrorsFetcher()
    this.disposeResourcesFetcher()
    this.disposeAvailabilityListFetcher()
    this.disposeBlackoutDatesListFetcher()
    this.disposePolicyFetcher()
    this.visitQueue.destroy()
    this.pastVisits.destroy()
    this.schedulesV2?.destroy()
    this.availabilityList.destroy()
    this.blackoutDatesList.destroy()
  }

  async fetchPolicies() {
    const servicePolicies = {
      medical: 'medical',
      'therapy-scheduled': 'therapy',
      'therapy-now': 'therapy',
      'therapy-all': 'therapy',
      psychiatry: 'psychiatry',
      hc: 'hc',
    }

    const response = await makeQuery('getPolicies')
    let responseTypes = new Set()
    response.map(({ types }) => types.map((type) => responseTypes.add(type)))
    responseTypes = Array.from(responseTypes).filter((type) =>
      this.isSuccessCoaching ? true : !this.isSuccessPolicy(type),
    )

    const typesToExclude = Object.values(servicePolicies).filter((type) => type !== servicePolicies[this.type])
    const typesToInclude = responseTypes.filter((type) => !typesToExclude.includes(type) && type !== null)
    const appliciablePolicies = response.filter((policy) => policy.types.some((type) => typesToInclude.includes(type)))

    const finalPolicies = []
    typesToInclude.map((type) =>
      finalPolicies.push({
        type: type,
        list: appliciablePolicies
          .filter((policy) => policy.types.some((policyType) => policyType === type))
          .sort((a, b) => (a.name > b.name ? 1 : -1)),
      }),
    )
    // Sort by length
    finalPolicies.sort((a, b) => b.list.length - a.list.length)

    // Move provider type policies to the front
    finalPolicies.sort((a, b) => {
      if (a.type === servicePolicies[this.type]) {
        return -1
      }
    })

    runInAction(() => {
      this.policies = finalPolicies
    })
  }

  isSuccessPolicy(type) {
    return type === 'success_coaching'
  }

  async fetchTransmissionErrors() {
    if (this.rxVendorId && !User.isHidePrescriptionsFeatureFlagEnabled) {
      const response = await makeQuery('getTransmissionErrors', {
        rxVendorId: this.rxVendorId,
      })
      runInAction(() => {
        this.transmissionErrorsList = response
      })
    }
  }
  async updateCurrentProvider() {
    const { provider, resources } = await makeQuery('getMe')
    this.merge({ ...provider, resources })
  }
  async changeSnoozeStatus(date) {
    const dateToSnooze = increaseDate(date)
    const snooze = dateToSnooze !== null ? new Date(dateToSnooze).toISOString() : null
    const response = await makeQuery('putProviderNotifications', {
      providerId: this.providerId,
      email: !!this.notifications.email,
      sms: !!this.notifications.sms,
      snooze: snooze,
    })
    this.merge(response)
  }

  async cancelTransmissionError({ transmissionError }) {
    const { prescriptionId, dateWritten, errorDateTimeStamp, errorDetails, patientId } = transmissionError || {}
    await makeQuery('postAcknowledgeTransmissionErrors2', {
      prescriptionId,
      dateWritten,
      errorDateTimeStamp,
      errorDetails,
      patientId,
    })
  }
  async sendVerification(phoneId) {
    await makeQuery('putVerificationSend', {
      providerId: this.providerId,
      phoneId,
    })
    GlobalEvents.emit('sendProviderVerification', {
      status: 'success',
    })
  }
  async verifyCode(phoneId, verificationCode) {
    const updatedProvider = await makeQuery('putPhoneVerify', {
      providerId: this.providerId,
      phoneId,
      verificationCode,
    })
    GlobalEvents.emit('verifyProviderCode', {
      status: 'success',
    })
    this.merge(updatedProvider)
  }
  /*   We can update schedule in "Blackout Dates" and "Set availability" modals
  so there are 2 types to recalc only one of them */
  async updateSchedule(type) {
    try {
      const schedule = {
        blackouts:
          type === 'blackoutDates'
            ? this.blackoutDatesList.dates.map((period) => period.pair)
            : this.schedule.blackouts,
        available: this.schedule.available === undefined ? true : this.acceptingVisits,
      }

      const availability =
        type === 'setAvailability'
          ? mapValues(this.availabilityList.formattedDays, (ranges) => mergeRanges(ranges))
          : formatInitAvailability(this.schedule.availability)

      schedule.availability = availability && Object.keys(availability).length ? availability : DIRTY_EMPTY_AVAILABILITY

      const updatedProvider = await makeQuery('putProviderSchedule', {
        providerId: this.providerId,
        schedule,
      })

      this.blackoutDatesList.recalc()

      GlobalEvents.emit('updateProviderSchedule', {
        status: 'success',
      })
      this.merge(updatedProvider)
    } catch (e) {
      GlobalEvents.emit('updateProviderSchedule', { status: 'error' })
      throw e
    }
  }

  async updateProvider({
    email,
    street1,
    street2,
    city,
    state,
    zip,
    phoneNumber,
    phoneType,
    acceptSmsNotifications,
    acceptEmailNotifications,
    profile,
  }) {
    const params = {
      providerId: this.providerId,
      userId: this.userId,
      externalId: this.providerId,
      rxVendorId: this.rxVendorId,
      vendorConfirmed: this.vendorConfirmed,
      dob: this.dob,
      timezone: this.timezone,
      dea: this.dea,
      npi: this.npi,
      prefix: this.prefix,
      firstName: this.firstName,
      middleInitial: this.middleInitial,
      lastName: this.lastName,
      credentials: this.credentials,
      modalities: this.modalities,
      priority: this.priority,
      licenses: this.licenses,
      education: this.education,
      paymentVisible: this.paymentVisible,
      paymentType: this.paymentType,
      email,
      addresses: getAddressesToSend(this.addresses, street1, street2, city, state, zip),
      phones: getPhonesToSend(this.phones, phoneNumber, phoneType, acceptSmsNotifications, acceptEmailNotifications),
      notifications: {
        sms: acceptSmsNotifications,
        email: acceptEmailNotifications,
      },
      profile,
    }
    const filteredParams = Object.fromEntries(
      Object.keys(params)
        .filter((k) => !shouldBeRemovedFromParams(params[k]))
        .map((k) => [k, params[k]]),
    )

    try {
      const updatedProvider = await makeQuery('putProvider', filteredParams)
      GlobalEvents.emit('updateProvider', {
        status: 'success',
      })
      this.merge(updatedProvider)
    } catch (e) {
      GlobalEvents.emit('updateProvider', { status: 'error' })
    }
  }
  setOpenedScheduledVisit(visit) {
    if (!visit) this.openedScheduledVisit = undefined
    if (typeof visit === 'string') this.openedScheduledVisit = this.visitQueue.getById(visit)
    else this.openedScheduledVisit = visit
  }
  clearOpenedScheduledVisit() {
    this.openedScheduledVisit = {}
  }
  setAcceptingVisits(acceptingVisits) {
    this.acceptingVisits = acceptingVisits
  }
  initalizeAcceptingVisits() {
    this.acceptingVisits = this.schedule.available
  }
  get isAcceptingVisitsDirty() {
    return this.acceptingVisits !== this.schedule.available
  }
  get welcomeTitle() {
    return `${this.prefix} ${this.lastName}`
  }
  get isMedical() {
    return this.licenses.some(({ type }) => type === 'medical')
  }

  get isSuccessCoaching() {
    return this.licenses.some(({ type }) => type === 'success_coaching')
  }

  get isSuccessCoachProvider() {
    return this.type === 'success'
  }

  get expiredLicenses() {
    const expiredLicenses = this.licenses.filter((license) => {
      return differenceInDays(new Date(license.expDate), new Date()) < DAYS_UNTIL_LICENSE_EXPIRE
    })
    // to show first license that expire sooner
    return expiredLicenses.sort((a, b) => {
      return new Date(a.expDate) - new Date(b.expDate)
    })
  }
  get isRxChangeAvailable() {
    return !User.isHidePrescriptionsFeatureFlagEnabled && !!this.rxChangesList.list.length
  }
  get phoneToVerify() {
    const { preferredPhone } = this
    return preferredPhone && !preferredPhone.verified ? preferredPhone : null
  }
  get address() {
    return getFullAddress(this.addresses, 'extended')
  }

  get formattedAddress() {
    return `${this.address?.street ? this.address?.street + ',' : ''} ${this.address?.state || ''}`
  }
  get smsNotificationStatus() {
    return getNotificationStatus(this.notifications.sms)
  }
  get emailNotificationStatus() {
    return getNotificationStatus(this.notifications.email)
  }

  get preferredAddress() {
    return getPreferred(this.addresses)
  }
  get preferredPhone() {
    return getPreferred(this.phones)
  }
  get phone() {
    return getPhone(this.phones)
  }
  get phoneType() {
    return this.preferredPhone?.type || '-'
  }
  get providerType() {
    return this.type
  }
  get showDiagnosticExpertiseFieldInAccounts() {
    return therapyProviderTypes.includes(this.providerType)
  }
  get showCommunityExpertiseFieldInAccounts() {
    return [...therapyProviderTypes, 'hc'].includes(this.providerType)
  }
  get showReligiousExpertiseFieldInAccounts() {
    return [...therapyProviderTypes, 'psychiatry'].includes(this.providerType)
  }
  get profileExist() {
    const isObjectValuesEmpty = (obj) =>
      Object.values(obj).every(
        (value) =>
          value === null ||
          value === undefined ||
          value === '' ||
          value === 0 ||
          (Array.isArray(value) && value.length === 0),
      )
    return Object.keys(this.profile).length > 0 && !isObjectValuesEmpty(this.profile)
  }

  get profileDiagnosticExpertise() {
    return filterNoneFromField(this.profile.diagnosticExpertise)
  }

  get profileCommunityExpertise() {
    return filterNoneFromField(this.profile.communityExpertise)
  }

  get profileReligiousExpertise() {
    return filterNoneFromField(this.profile.religiousExpertise)
  }

  get parsedProfile() {
    const { licenses, languages } = this.profile || {}

    return {
      ...(this.profile || {}),
      licenses: getJoinedFields(licenses),
      languages: getJoinedFields(languages),
      diagnosticExpertise: getJoinedFields(this.profileDiagnosticExpertise),
      communityExpertise: getJoinedFields(this.profileCommunityExpertise),
      religiousExpertise: getJoinedFields(this.profileReligiousExpertise),
    }
  }
  get languagesAbbrevs() {
    return this.languages.map(({ abbrev }) => abbrev)
  }
  get uniqueLicenseStates() {
    return [...new Set(this.licenses.map((license) => license.state))]
  }
  get uniqueLicenseTypes() {
    return [...new Set(this.licenses.map((license) => license.type))]
  }
  get fullName() {
    return [this.firstName, this.lastName].filter(Boolean).join(' ')
  }
  get fullNameAndCredentials() {
    const credentialsString = this.parsedProfile?.licenses

    return getJoinedFields([this.fullName, credentialsString].filter(Boolean))
  }

  get scheduleVersionConfig() {
    const { isPwNewScheduleFlagEnabled } = User
    const isV3Schedule = this.schedule?.version === 'v3'

    const showBothSchedules = !!isPwNewScheduleFlagEnabled && !isV3Schedule
    const showV1Schedule = showBothSchedules || !isV3Schedule
    const showV3Schedule = showBothSchedules || isV3Schedule

    return { showBothSchedules, showV1Schedule, showV3Schedule }
  }
}
export default Provider
