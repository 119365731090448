import { makeObservable, computed, comparer } from 'mobx'
import User from 'singletons/User'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import { formatDate } from 'util/formatters'
import VisitTypes from 'constants/visit-types'

/*
  Sorting priority:
    1. claimed/unclaimed
    2. by sheduledAt

  true - false === 1;
  false - true === -1;
*/
const visitsSorter = (a, b) => b.claimed - a.claimed || new Date(a.scheduledAt) - new Date(b.scheduledAt)

class VisitsCollection {
  list = []
  map = new Map()

  constructor(provider) {
    makeObservable(this, {
      list: true,
      map: true,
      length: true,
      removeByPendingVisitId: true,

      byStatus: true,
      byScheduledDate: true,
      pusherPendingVisits: true,
      hasClaimedPending: true,
      filteredList: computed({ equals: comparer.shallow }),

      visitsForCalendar: computed({ equals: comparer.shallow }),
      queuedTalkNowAndMedicalVisits: computed({ equals: comparer.shallow }),
      queuedCrisisVisits: computed({ equals: comparer.shallow }),
      scheduledPendingVisits: computed({ equals: comparer.shallow }),
      inProgressVisits: computed({ equals: comparer.shallow }),
      assignedVisits: computed({ equals: comparer.shallow }),
      inReviewVisits: computed({ equals: comparer.shallow }),
    })
    this.provider = provider
  }

  destroy() {
    for (const visit of this.list) {
      visit.destroy()
    }
  }

  getById(id) {
    return this.map.get(id)
  }

  providerIdEqualsUser(providerId) {
    return User.provider?.providerId === providerId
  }

  get length() {
    return this.list.length
  }

  /* Provider with externalId => show visits with externalId present( may not be the same )
  Provider with no externalId => show only visits with no externalId */
  get filteredList() {
    return this.list.filter(
      (v) =>
        (!v.providerId || this.providerIdEqualsUser(v.providerId)) &&
        !!User.provider?.externalId === !!v.externalId &&
        User.provider?.modalities.includes(v.modality),
    )
  }

  get byStatus() {
    return groupBy(this.filteredList, 'status')
  }

  get pusherPendingVisits() {
    return [
      ...(this.byStatus['pending'] || []),
      ...(this.byStatus['in-progress'] || []),
      ...(this.byStatus['in-review'] || []),
    ]
  }

  get queuedTalkNowAndMedicalVisits() {
    return (
      this.byStatus['pending']
        ?.filter(
          ({ clientScheduledType, visitType }) => clientScheduledType === 'now' && visitType !== VisitTypes.crisis,
        )
        .sort(visitsSorter) ?? []
    )
  }

  get queuedCrisisVisits() {
    if (!User.getFeatureFlagByName('crisisLine')) {
      return []
    }
    return this.byStatus['pending']?.filter(({ visitType }) => visitType === VisitTypes.crisis).sort(visitsSorter) ?? []
  }

  get scheduledPendingVisits() {
    return (
      this.byStatus['pending']
        ?.filter(({ clientScheduledType }) => clientScheduledType === 'scheduled')
        .sort(visitsSorter) ?? []
    )
  }

  get hasClaimedPending() {
    return this.queuedTalkNowAndMedicalVisits.some((v) => v.claimed)
  }

  get inProgressVisits() {
    return [
      ...(this.byStatus['in-progress'] || []),
      ...this.filteredList.filter(({ clientScheduledType }) => clientScheduledType === 'scheduled-shown-as-active'),
    ].sort(visitsSorter)
  }

  get inReviewVisits() {
    return [...(this.byStatus['in-review'] || [])].sort(visitsSorter)
  }

  get assignedVisits() {
    return (
      this.byStatus['pending']
        ?.filter(
          ({ clientScheduledType, providerId }) =>
            clientScheduledType === 'now' && this.providerIdEqualsUser(providerId),
        )
        .sort(visitsSorter) ?? []
    )
  }

  get hasInProgressVisit() {
    return !!this.inProgressVisits.length
  }

  removeByPendingVisitId(pendingVisitId) {
    if (this.map.delete(pendingVisitId)) {
      const idx = this.list.findIndex((a) => a.pendingVisitId === pendingVisitId)
      if (idx !== -1) {
        this.list[idx].destroy()
        this.list.splice(idx, 1)
      }
    }
  }

  get byScheduledDate() {
    return mapValues(
      groupBy(this.visitsForCalendar, ({ scheduledAt }) => formatDate(scheduledAt)),
      (v) => v.sort((a, b) => new Date(a.scheduledAt) - new Date(b.scheduledAt)),
    )
  }
  get visitsForCalendar() {
    /* TODO: maybe filteredList here? */
    // TODO: Should calendar show now completed? If so use the isActual getter here.
    return this.list.filter(({ status, scheduledType }) => scheduledType === 'scheduled' && status !== 'cancelled')
  }
}

export default VisitsCollection
