import { CrisisStreamResponse } from 'types/Crisis/crisis-stream'
import { InferType, object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  providerId: string().required(),
  providerName: string().required(),
  claimedById: string().nullable().optional(),
  claimedBy: string().nullable().optional(),
  claimedByName: string().nullable().optional(),
  eventStreamId: string().required(),
}).required()

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = CrisisStreamResponse

export const url = ({ eventStreamId }: { eventStreamId: string }) =>
  `/v1/crisis-streams/${eventStreamId}/assign-provider`

export const getBody = ({ providerId, providerName, claimedById, claimedBy, claimedByName }: Request) => ({
  providerId,
  providerName,
  claimedById,
  claimedBy,
  claimedByName,
})
