import { object, string } from 'yup'
export { provider as baseURL } from 'constants/apiBaseUrls'
import type { TicketComment } from 'components/tickets/types'

export const Schema = object().shape({
  noteId: string().required(),
})

export type Request = {
  noteId: string
}

export const url = ({ noteId }: Request) => `/v1/notes/${noteId}/comments`

export type SuccessfulResponse = TicketComment[]
