import { InferType, object, string } from 'yup'
import User from 'singletons/User'
import { CreateAvailabilityFormSchema, createProviderScheduleBody } from '@ui-components-3/provider-schedule-calendar'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = CreateAvailabilityFormSchema.concat(
  object({
    id: string().required(),
  }),
)

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = { message: string }

export const url = ({ id }: Request) => `/v3/providers/${User.providerId}/schedules/${id}`

export const getBody = createProviderScheduleBody
