import { object, string } from 'yup'
export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  memberId: string().required(),
})

export type Request = {
  memberId: string
}

export const url = ({ memberId }: Request) => `/v1/tickets/members/${memberId}`

export type SuccessfulResponse = any
