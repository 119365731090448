import { CrisisStreamResponse } from 'types/Crisis/crisis-stream'
import { InferType, object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  providerId: string().required(),
  providerPhone: string().required(),
  eventStreamId: string().required(),
}).required()

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = CrisisStreamResponse

export const url = ({ eventStreamId }: { eventStreamId: string }) =>
  `/v1/crisis-streams/${eventStreamId}/assign-provider-phone`

export const getBody = ({ providerId, providerPhone }: Request) => ({
  providerId,
  providerPhone,
})
