import { object, string } from 'yup'
export { provider as baseURL } from 'constants/apiBaseUrls'
import type { Ticket } from 'components/tickets/types'

export const Schema = object().shape({
  visitId: string().required(),
})

export type Request = {
  visitId: string
}

export const url = ({ visitId }: Request) => `/v1/tickets/visits/${visitId}`

export type SuccessfulResponse = Ticket[]
