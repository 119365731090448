import { add } from 'date-fns/add'

import { HOUR, DAY, TWO_DAYS, WEEK, TWO_WEEKS } from 'constants/snoozeOptions'

const addAmount = {
  [HOUR]: { hours: 1 },
  [DAY]: { days: 1 },
  [TWO_DAYS]: { days: 2 },
  [WEEK]: { weeks: 1 },
  [TWO_WEEKS]: { weeks: 2 },
}

const increaseDate = (amount) => {
  const addValue = addAmount[amount]

  if (!addValue) {
    return null
  }
  return add(new Date(), addValue)
}

export default increaseDate
