import { InferType, object, string } from 'yup'
import queryString from 'qs'
import { toApiNormal } from 'api/makeQuery'
import { ProviderScheduleSlot, ProviderScheduleSlotsFilterSchema } from '@ui-components-3/provider-schedule-calendar'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  providerId: string().required(),
  filter: ProviderScheduleSlotsFilterSchema,
})

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = { count: number; slots: ProviderScheduleSlot[] }

export const url = ({ providerId, filter }: Request) =>
  `/v3/providers/${providerId}/slots?${queryString.stringify(toApiNormal({ filter }), { indices: false })}`
