import { InferType, object, string } from 'yup'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  providerId: string().required(),
  blockId: string().required(),
})

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = { message: string }

export const url = ({ providerId, blockId }: Request) => `/v3/providers/${providerId}/schedules/${blockId}`
