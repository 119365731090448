import { object, array, number, string, InferType } from 'yup'
import queryString from 'qs'
import { PaginatedResponse } from 'types/api'
import PendingVisit, { PendingVisitsParams } from 'types/Visit/PendingVisit'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object({
  lt: object({
    scheduled_at: string().optional(),
  }),
  gt: object({
    scheduled_at: string().optional(),
  }),
  filter: object({
    member_id: string().notRequired(),
    status: array().of(string()),
    provider_id: string().notRequired(),
    visit_type: string().notRequired(),
    start_date: string().notRequired(),
    scheduled_type: array().of(string()).notRequired(),
  }),
  sort: object(),
  page: object({
    skip: number(),
    limit: number(),
  }).required(),
})

export type Request = PendingVisitsParams

export type SuccessfulResponse = PaginatedResponse<PendingVisit>

export const url = ({ filter, gt, lt, sort, page }) =>
  `/v1/visits/pending?${queryString.stringify({ filter, gt, lt, sort, page }, { indices: false })}`

export const shouldNotLogoutOn403Error = true
