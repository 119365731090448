export const VisitTypes = {
  medical: 'medical',
  therapy: 'therapy',
  therapyNow: 'therapy_now',
  psychiatry: 'psychiatry',
  healthCoach: 'hc',
  successNow: 'success_coaching_now',
  success: 'success_coaching',
  crisis: 'crisis',
} as const

export const isSuccessVisit = (visitType) => visitType === VisitTypes.success || visitType === VisitTypes.successNow

export const DisplayType = {
  [VisitTypes.medical]: 'Medical',
  [VisitTypes.therapy]: 'Therapy',
  [VisitTypes.therapyNow]: 'Talk Now',
  [VisitTypes.psychiatry]: 'Psychiatry',
  [VisitTypes.healthCoach]: 'Health Coaching',
  [VisitTypes.successNow]: 'Success Coaching',
  [VisitTypes.success]: 'Success Coaching',
  [VisitTypes.crisis]: 'Crisis',
}

export default VisitTypes
