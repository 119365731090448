import { object, string } from 'yup'
export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = object().shape({
  ticketId: string().required(),
})

export type Request = {
  ticketId: string
}

export const url = ({ ticketId }: Request) => `/v1/tickets/${ticketId}/notes`

export type SuccessfulResponse = any
