import { runInAction } from 'mobx'
import mergeMobxListsBy from 'util/mergeMobxListsBy'
import VisitsCollection from '../VisitsCollection'
import PendingVisit from '../visits/PendingVisit'
import { formatISO } from 'date-fns/formatISO'
import { startOfMonth } from 'date-fns/startOfMonth'
import { endOfMonth } from 'date-fns/endOfMonth'
import makeQuery from 'api/makeQuery'
import { API_FETCH_LIMIT } from 'constants/apiFetchLimits'

class VisitQueueCollection extends VisitsCollection {
  mergeQueue(queue) {
    runInAction(() => mergeMobxListsBy(this.map, this.list, queue, 'pendingVisitId', (v) => new PendingVisit(v), false))
  }

  async refetchVisit(pendingVisitId) {
    const result = await makeQuery('getPendingVisit', {
      visitId: pendingVisitId,
    })
    runInAction(() => {
      // we don't know if having an embedded visit will break current functionality
      delete result.visit
      this.mergeQueue([result])
    })
  }

  async refetchMonth(date) {
    const visits = await makeQuery('getPendingVisits', {
      filter: {
        provider_id: this.provider.providerId,
        status: ['pending', 'completed'],
        scheduled_type: ['scheduled'],
      },
      gt: {
        scheduled_at: formatISO(startOfMonth(date)),
      },
      lt: {
        scheduled_at: formatISO(endOfMonth(date)),
      },
      page: {
        skip: 0,
        limit: API_FETCH_LIMIT,
      },
    })
    this.mergeQueue(visits.paginated)
  }
}

export default VisitQueueCollection
