import { makeObservable, runInAction } from 'mobx'
import mapValues from 'lodash/mapValues'
import { differenceInYears } from 'date-fns/differenceInYears'

import makeQuery from 'api/makeQuery'
import User from 'singletons/User'
import GlobalEvents from 'singletons/GlobalEvents'
import Record from 'models/Record'
import { formatDate, phoneInputFormat } from 'util/formatters'
import { getHeight, getWeight, getDosespotGender, getAddressStreet, getAddressState } from 'util/apiFieldFormatters'
import RequestedMedication from '../RequestedMedication'
import { PRESCRIBER_AUTH_SUB_TYPES_MAP } from 'constants/prescriberAuthSubTypes'

class RxChange extends Record {
  static INITIAL_FIELDS = {
    clinic: null,
    clinician: null,
    drugUseEvaluations: null,
    newPatient: false,
    originalPrescriptionId: 0,
    patient: null,
    payerName: null,
    pharmacy: null,
    pharmacyId: 0,
    prescribedMedication: null,
    prescribedMedicationForRxchange: null,
    requestedDate: '',
    requestedMedications: null,
    requestedMedicationsForRxchange: null,
    rxChangeRequestId: 0,
    rxChangeType: '',
    rxChangeSubType: '',
    unmatchedChange: false,
    prescribedPrescriptionId: 0,
  }

  requestedMedicationsList = []

  constructor(valuesObj) {
    super(valuesObj)
    makeObservable(this, {
      ...mapValues(this.constructor.INITIAL_FIELDS, () => true),
    })
  }

  async getPharmacy() {
    const data = await makeQuery('getRxChangePharmacy', {
      pharmacyId: this.pharmacyId,
    })

    runInAction(() => {
      this.pharmacy = data
    })
  }

  async getPrescribedPrescription() {
    const data = await makeQuery('getRxChangePrescriptionDetails', {
      providerRxVendorId: User.provider.rxVendorId,
      patientRxVendorId: this.patient?.patientId,
      prescriptionId: this.originalPrescriptionId,
    })
    runInAction(() => {
      this.prescribedMedication = data?.prescription
    })
  }

  async getRequestedMedicationsList() {
    try {
      const promises = (this.requestedMedications || []).map(async (m) => {
        try {
          const data = await makeQuery('getRxChangePrescriptionDetails', {
            providerRxVendorId: User.provider.rxVendorId,
            patientRxVendorId: this.patient?.patientId,
            prescriptionId: m,
          })

          return data?.prescription
        } catch (error) {
          console.log(error)
          return null
        }
      })

      const list = await Promise.all(promises)
      this.requestedMedicationsList = list.filter(Boolean).map((m) => new RequestedMedication(m))
    } catch (error) {
      console.log(error)
      this.requestedMedicationsList = []
    }
  }

  async getDetails() {
    try {
      await Promise.all([this.getPharmacy(), this.getPrescribedPrescription(), this.getRequestedMedicationsList()])
    } catch (error) {
      console.log(error)
    }
  }

  putApproveChangeRequest = async ({
    selectedMedicationIndex,
    priorAuthNumber,
    prescriberAuthorizationData,
    prescriberLicenseInactive,
    newPrescription,
  }) => {
    const selectedMedication = this.requestedMedicationsList[selectedMedicationIndex]

    try {
      const body = {
        rxChangeRequestId: this.rxChangeRequestId,
        providerId: User.provider.rxVendorId,
        prescriptionExternalId: this.originalPrescriptionId,
        newPrescriptionPatientId: this.patient.patientId,
        selectedMedicationId: this.requestedMedicationsList.length
          ? selectedMedication?.prescriptionId
          : this.prescribedMedication?.prescriptionId,
        newPrescription: newPrescription
          ? // dosespot expects status as integer, but new prescription in v2 returns string status instead, so we decided to hardcode it to 1 during approval
            { ...newPrescription, status: 1 }
          : null,
        priorAuthNumber: this.isPriorAuthType ? priorAuthNumber : undefined,
        prescriberLicenseInactive: this.showPrescriberLicenseInactiveCheckbox ? prescriberLicenseInactive : undefined,
        prescriberAuthorizationData: this.isPrescriberAuthType ? prescriberAuthorizationData : undefined,
      }
      const res = await makeQuery('putChangeRequestApprove', body)

      GlobalEvents.emit('confirmRxChangeRequest', {
        status: 'success',
      })

      return res
    } catch (e) {
      GlobalEvents.emit('confirmRxChangeRequest', {
        status: 'error',
        e,
      })
    }
  }

  putDenyChangeRequest = async ({ reason }) => {
    try {
      const res = await makeQuery('putChangeRequestDeny', {
        rxChangeRequestId: this.rxChangeRequestId,
        providerId: User.provider.rxVendorId,
        reason,
      })

      GlobalEvents.emit('denyRxChangeRequest', {
        status: 'success',
      })

      return res
    } catch (e) {
      GlobalEvents.emit('denyRxChangeRequest', {
        status: 'error',
        e,
      })
    }
  }

  get patientFullName() {
    const firstName = this.patient?.firstName
    const lastName = this.patient?.lastName
    if (firstName && lastName) {
      return `${firstName} ${lastName}`
    }
    return ''
  }

  get patientAge() {
    const dob = this.patient?.dateOfBirth
    if (dob) {
      return differenceInYears(Date.now(), new Date(dob))
    }
    return ''
  }

  get patientAddress() {
    const address1 = this.patient?.address1 || ''
    const address2 = this.patient?.address2 || ''
    if (address1 || address2) {
      return `${address1} ${address2}`
    }
    return ''
  }

  get patientState() {
    const city = this.patient?.city || ''
    const state = this.patient?.state || ''
    const zipCode = this.patient?.zipCode || ''
    if (city && state) {
      return `${city}, ${state} ${zipCode}`
    }
    return ''
  }

  get patientPhone() {
    return this.patient?.primaryPhone || ''
  }

  get patientGender() {
    const genderId = this.patient?.gender
    return getDosespotGender(genderId)
  }

  get patientDob() {
    return formatDate(this.patient?.dateOfBirth)
  }

  get patientHeight() {
    const heightField = this.patient?.height
    if (heightField) {
      const totalHeight = heightField / 12
      const heightFeet = Math.floor(totalHeight)
      const heightInches = Math.floor((totalHeight - heightFeet) * 12)
      return getHeight(heightFeet, heightInches)
    }
    return '-'
  }

  get patientWeight() {
    const weightField = this.patient?.weight
    if (weightField) {
      return getWeight(weightField)
    }
    return '-'
  }

  get medicationName() {
    return this.prescribedMedication?.displayName || ''
  }

  get medicationUnit() {
    const unitDescription = this.prescribedMedication?.dispenseUnitDescription
    const strength = this.prescribedMedication?.strength
    if (unitDescription && strength) {
      return `${strength} ${unitDescription}`
    }
    return ''
  }

  get medicationDirections() {
    return this.prescribedMedication?.directions || ''
  }

  get medicationQuantity() {
    return this.prescribedMedication?.quantity || ''
  }

  get medicationRefills() {
    return this.prescribedMedication?.refills || ''
  }

  get medicationDaysSupply() {
    return this.prescribedMedication?.daysSupply || 0
  }

  // Expand medication
  get medicationNotes() {
    return this.prescribedMedication?.pharmacyNotes || ''
  }

  get medicationSubstitutions() {
    return this.prescribedMedication?.noSubstitutions ? 'Not Allowed' : 'Allowed'
  }

  get medicationWrittenDate() {
    return this.prescribedMedication?.writtenDate && formatDate(this.prescribedMedication.writtenDate)
  }

  get clinicianData() {
    return this.clinician || User.provider
  }

  get prescriberFullName() {
    const firstName = this.clinicianData?.firstName
    const lastName = this.clinicianData?.lastName
    if (firstName && lastName) {
      return `Dr. ${firstName} ${lastName}`
    }
    return ''
  }

  get prescriberAddress() {
    return this.clinicianData ? getAddressStreet(this.clinicianData) : ''
  }

  get prescriberState() {
    return this.clinicianData ? getAddressState(this.clinicianData) : ''
  }

  get prescriberFullAddress() {
    return (
      [this.prescriberAddress, this.prescriberState].filter((i) => !!i && i !== '-').join(', ') ||
      this.clinicianData?.formattedAddress ||
      '-'
    )
  }

  get prescriberPhone() {
    return phoneInputFormat(this.clinicianData?.primaryPhone) || this.clinicianData?.phone || '-'
  }

  get isPriorAuthType() {
    return this.rxChangeType === 'Prior Authorization'
  }

  get isPrescriberAuthType() {
    return this.rxChangeType === 'Prescriber Authorization'
  }

  get isStateLicenseStatusSubType() {
    return this.rxChangeSubType === 'State License Status'
  }

  get showPrescriberLicenseInactiveCheckbox() {
    return this.isPrescriberAuthType && this.isStateLicenseStatusSubType
  }
  get prescriberAuthorizationDataLabel() {
    return this.rxChangeSubType ? PRESCRIBER_AUTH_SUB_TYPES_MAP[this.rxChangeSubType].label : ''
  }
  get prescriberAuthorizationDataType() {
    return this.rxChangeSubType ? PRESCRIBER_AUTH_SUB_TYPES_MAP[this.rxChangeSubType].type : ''
  }
}

export default RxChange
