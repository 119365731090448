import { InferType } from 'yup'
import { CreateAvailabilityFormSchema, createProviderScheduleBody } from '@ui-components-3/provider-schedule-calendar'
import User from 'singletons/User'

export { provider as baseURL } from 'constants/apiBaseUrls'

export const Schema = CreateAvailabilityFormSchema

export type Request = InferType<typeof Schema>

export type SuccessfulResponse = { message: string }

export const url = () => `/v3/providers/${User.providerId}/schedules`

export const getBody = createProviderScheduleBody
