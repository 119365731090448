import { makeObservable, action } from 'mobx'
import { format } from 'date-fns/format'
import { parseISO } from 'date-fns'
let uId = 0
const pairSorter = (a, b) => new Date(a) - new Date(b)
const formatPeriod = (period) => {
  if (!period) return ''
  const formattedTime = format(new Date(period), 'HH:mm:ss')
  return +new Date(`01/01/2000 ${formattedTime.slice(0, 6)}00`)
}
const formatPeriodToSend = (date, time) => {
  const initDate = new Date(date)
  const initTime = new Date(time)
  const hours = initTime.getHours()
  const minutes = initTime.getMinutes()
  initDate.setHours(hours)
  initDate.setMinutes(minutes)
  return initDate.toISOString()
}
const formatDate = (date) => (date ? parseISO(date) : date)
class Period {
  id = uId++
  startDate = ''
  startTime = ''
  endDate = ''
  endTime = ''
  isChanged = false
  isNewDate = false
  constructor(blackoutDatesCollection, period, isNewDate = false) {
    makeObservable(this, {
      startDate: true,
      startTime: true,
      endDate: true,
      endTime: true,
      isChanged: true,
      setStartDate: action.bound,
      setStartTime: action.bound,
      setEndDate: action.bound,
      setEndTime: action.bound,
      delete: action.bound,
      pair: true,
      isNewDate: true,
    })

    this.startDate = formatDate(period[0])
    this.startTime = formatPeriod(period[0])
    this.endDate = formatDate(period[1])
    this.endTime = formatPeriod(period[1])
    this.blackoutDatesCollection = blackoutDatesCollection
    this.isNewDate = isNewDate
  }
  setStartDate(value) {
    this.startDate = value
    this.isChanged = true
  }
  setStartTime(value) {
    this.startTime = value
    this.isChanged = true
  }
  setEndDate(value) {
    this.endDate = value
    this.isChanged = true
  }
  setEndTime(value) {
    this.endTime = value
    this.isChanged = true
  }
  delete() {
    this.blackoutDatesCollection.deletePeriod(this.id)
  }
  touchesDay(date) {
    const d = new Date(date).setHours(0, 0, 0, 0)
    const start = new Date(this.startDate).setHours(0, 0, 0, 0)
    const end = new Date(this.endDate).setHours(23, 59, 59, 999)
    return d >= start && d <= end
  }
  get pair() {
    if (!this.ready) throw new Error('Should not be called on unitialized period')
    const pairStart = formatPeriodToSend(this.startDate, this.startTime)
    const pairEnd = formatPeriodToSend(this.endDate, this.endTime)
    return [pairStart, pairEnd].sort(pairSorter)
  }
  get formattedPair() {
    return this.pair.map((d) => format(new Date(d), 'MMM dd hh:mm aaa'))
  }
  get ready() {
    return !!(this.startDate && this.endDate && this.startTime && this.endTime)
  }
  get maxDate() {
    const date = new Date()
    date.setYear(date.getFullYear() + 1)
    return date
  }
}
export default Period
